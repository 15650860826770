.hub-wrapper .top-tab {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hub-wrapper .top-tab h5 {
  font-size: 1.5rem;
  font-weight: 600;
}

.hub-wrapper .tabs {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
}

.hub-wrapper .tabs .tab {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.6rem;
  color: initial;
  border-bottom: 1px solid transparent;
  cursor: pointer;
}

.hub-wrapper .tabs .tab h5 {
  color: #64748B;
  font-weight: normal;
}

.hub-wrapper .tabs .tab.active {
  color: #50B1C9;
  border-bottom: 1px solid #50B1C9 !important;
}

.hub-wrapper .tabs .tab.active h5 {
  color: #50B1C9;
}

.hub-wrapper .tabs .tab.active svg {
  color: #50B1C9;
}

.hub-wrapper .icons {
  display: flex;
  align-items: center;
  gap: 0.2rem;
}

.hub-wrapper .icons svg {
  width: 22px;
  height: 22px;
  cursor: pointer;
}