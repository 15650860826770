.flex-head-hubdesign {
  flex-direction: column;
  align-items: flex-start;
}

.flex-group-input {
  flex-direction: column;
  align-items: flex-start;
}

.profile-avatar-data {
  flex-direction: row;
  align-items: center;
}

.content-profile-avatar {
  flex-direction: row;
  /* align-items: flex-start; */
}

.item-list-edit-input {
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
}

.flex-edit-avatar-image {
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
}

.wrapper-edit-password {
  margin-top: 20px;
}

.group-input-invite-team {
  flex-direction: row;
}

.cover-input-invite-team {
  max-width: 100%;
}

.select-invite-team {
  max-width: 100%;
}