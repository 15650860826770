.audience-modal {}

.audience-modal .model-avatar-data {
    background-color: #F8F9FB;
}

.audience-modal .model-avatar-data {
    max-width: 80vw;
}

.audience-modal .modal-header{
    padding: 0px !important;
    border-bottom: none;
}

.modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
}

.modal-header::before {
    display: none;
}
.modal-header::after {
    display: none;
}

.modal-header .left {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.modal-header .left img {
    width: 80px;
    height: 80px;
    border-radius: 100px;
}

.modal-header .left .title h5 {
    color: #333;
    font-size: 12px;
}

.modal-header .left .title p {
    font-size: 12px;
}

.modal-header .left .title {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
}

.modal-header .right .icons {
    padding: 0.4rem;
    border: 1px solid #E1E7EB;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.2rem;
}

.modal-header .right .icons svg {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.audience-modal .menu-tab-avatar {
    padding: 0 2rem;
    min-height: 35px;
}

.audience-modal .tab-container-avatar-model {
    background-color: white;
    height: 100%;
    padding: 32px;
}

/* Attributes */
.attributes {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.attributes .item {
    display: flex;
    align-items: center;
}

.attributes .item .title {
    width: 200px;
    color: #64748B;
}

.attributes .item .value {
    display: flex;
    align-items: center;
    gap: 1rem;
    color: #0A0C0F;
}

.attributes .item .value .learning {
    text-transform: capitalize;
    padding: 0.2rem 0.4rem;
    background-color: #F9FAFB;
}

/* Activity */
.activity {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.activity table {
    min-width: 100%;
    border-spacing: none !important; 
    border-collapse: none !important;
}

.activity .table-avatar-model {
    border-radius: 8px;
    border: 1px solid #E5E7EB;
    margin-bottom: 16px;
    overflow: hidden;
    display: table;
    border-collapse: separate !important;
}

.activity .td-table-avatar-model{
    padding: 6px 11px;
}
.activity .td-table-avatar-model:nth-child(1) {
    width: 60px;
}

.activity .activity-table-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.activity .activity-table-wrapper .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.activity .activity-table-wrapper .title h5 {
    color: #0A0C0F;
    font-size: 16px;
    font-weight: 700;
}

.activity .activity-table-wrapper .title p {
    font-weight: normal;
    font-size: 12px;
}

.activity .activity-status {
    display: flex;
    padding: 4px 12px;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid #E5E7EB;
    font-size: 13px;
    font-weight: 400;
    line-height: 153.846%;
}
.activity .activity-status:before {
    content: "";
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 100%;
    background: #57A768;
}
.activity .activity-status.completed:before {
    background: #346297;
}

/* Responses */
.responses {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.responses h5.title {
    color: #0A0C0F;
    font-size: 16px;
    font-weight: 700;
}

.responses .option {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.responses .content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin: 0px !important;
    padding: 0px !important;
    background: none !important;
}

.responses .content .item {
    padding: 10px;
    border: 1px solid #ECECEF;
    display: flex;
    gap: 16px;
    border-radius: 10px;
    justify-content: space-between;
}

.content .item h5 {
    color: #0A0C0F;
    font-weight: 700;
    font-size: 13.6px;
    margin-bottom: 5px;
}

.content .item h3 {
    color: #0A0C0F;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 5px;
}

.content .item p {
    margin-left: 16px;
    color: #64748B;
    font-weight: normal;
}

.content .item .right {
    width: 200px;
}

.content .item .right p {
    font-size: 12px;
    color: #8993A4;
}

/* Files */

.files .option .search {
    display: flex;
    padding: 6px 12px;
    gap: 8px;
    border: 1px solid #E1E7EB;
    border-radius: 10px;
}

.files .option .search svg {
    width: 25px;
    height: 25px;
}

.files .option .search input {
    border: none;
    outline: none;
}

.files .files-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
}

.files .files-content .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #F7F8F9;
    padding: 12px;
    border-radius: 10px;
}

.files .files-content .left {
    display: flex;
    align-items: center;
    gap: 10px;
}

.files .files-content .left svg {
    width: 30px;
    height: 30px;
}

.files .files-content .left .title {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.files-content .left .title h5 {
    color: #0A0C0F;
    font-weight: 700;
    font-size: 12px;
}

.files-content .left .title p {
    font-size: 12px;
    font-weight: normal;
}

.files-content .right {
    display: flex;
    align-items: center;
    justify-content: center;
}

.files-content .right .outline {
    border-radius: 10px;
    padding: 10px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.files-content .right .outline svg {
    width: 15px;
    height: 15px;
}

.files-content .fa{
    font-size: 25px;
    color:gainsboro;
}