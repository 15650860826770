/*Header*/
.header {
    background: #21304F;
}

.header-flex {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    min-height: 64px;
}

.header-left {
    display: flex;
    gap: 36px;
    align-items: center;
}

.header-right {
    display: flex;
    gap: 16px;
    align-items: center;
}

.navbar-list {
    display: flex;
    height: 100%;
}

.navbar-menu {
    height: 100% !important;
}

@media only screen and (min-width: 769px) {
    .navbar-menu {
        display: block !important;
    }
}

.nav-link {
    padding: 0 12px;
    color: #fff;
    border-bottom: 1.5px solid transparent;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.nav-link:hover {
    color: #64BBCD;
}

.nav-link.active {
    color: #64BBCD;
    border-color: #64BBCD;
}

/*Header Icon*/
.header-icons {
    display: flex;
    gap: 8px;
}

.user-notification,
.user-mail {
    color: #CBD0D7;
    display: flex;
}

.user-notification:hover,
.user-mail:hover {
    color: #64BBCD;
}

/*Header User*/
.header-user {
    display: flex;
    gap: 8px;
    cursor: pointer;
    position: relative;
}

.user-avatar {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    overflow: hidden;
    flex-shrink: 0;
}

.wrapper-user-name {
    color: #FFF;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: -0.035px;
    display: flex;
    align-items: center;
}

.user-name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.user-arrow-icon {
    line-height: 0;
    color: #BAC0CD;
}

.header-user:hover .user-name,
.header-user:hover .user-arrow-icon {
    color: #64BBCD;
}

.user-dropdown-menu {
    position: absolute;
    top: 120%;
    right: 0;
    left: auto;
    background: #ffffff;
    border-radius: 6px;
    min-width: 200px;
    box-shadow: 0 0 10px rgba(0 0 0 / 30%);
    overflow-y: hidden;
    transition-property: all;
    transition-duration: 0.2s;
    max-height: 0;
    padding: 0 15px;
}

.user-dropdown-menu.active {
    max-height: 800px;
    padding: 15px 15px;
}

.user-dropdown-menu-list {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.user-dropdown-menu-link {
    display: block;
    padding: 5px;
    color: #64BBCD;
    border-radius: 3px;
    display: flex;
    gap: 8px;
}

.user-dropdown-menu-link:hover {
    background: #64BBCD;
    color: #fff;
}